import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';
import { useLocalization, useMixpanelEvents } from 'hooks';
import { getISOStringWithoutMilliseconds } from '@guider-global/front-end-utils';
import { useProfiles, useRelationships } from '@guider-global/front-end-hooks';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { useNavigate } from 'react-router-dom';
import { ProgramType } from '@guider-global/shared-types';

interface ConcludeRelationshipHook {
  relationshipId: string;
  organizationSlug: string;
  programType: Partial<ProgramType> | undefined;
  isGroupProgram: boolean;
  programSlug: string | undefined;
  isGuide: boolean;
  isActiveGroup?: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const useConcludeRelationship = ({
  relationshipId,
  isGroupProgram,
  isGuide,
  programSlug,
  programType,
  organizationSlug,
  setIsLoading,
}: ConcludeRelationshipHook) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { trackMixpanelEvent } = useMixpanelEvents();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const { reqRelationships, relationshipsRevalidate } = useRelationships({});
  const { profiles } = useProfiles({});
  const profile = profiles?.at(0);
  const profileId = profile?.id;

  const getModalConfig = () => {
    const groupGuide =
      programType?.program_type_text?.variations?.group?.relationships?.guide
        ?.conclude_relationship;
    const groupTrainee =
      programType?.program_type_text?.variations?.group?.relationships?.trainee
        ?.conclude_relationship;
    const individual =
      programType?.program_type_text?.variations?.individual?.relationships
        ?.conclude_relationship;

    let heading: string;
    let subtitle: string;
    let dialogTitle: string;
    let dialogDescription: string;
    let concludeRelationshipButtonLabel: string;
    const cancelButtonLabel =
      baseLanguage?.globals?.common?.cancel_button_label ?? 'Cancel';

    if (isGroupProgram) {
      if (isGuide) {
        heading = groupGuide?.button_title ?? '';
        subtitle = groupGuide?.button_description ?? '';
        dialogTitle = groupGuide?.conclude_relationship_modal.title ?? '';
        dialogDescription =
          groupGuide?.conclude_relationship_modal.description ?? '';
        concludeRelationshipButtonLabel =
          groupGuide?.button_title ?? 'Conclude';
      } else {
        heading = groupTrainee?.button_title ?? '';
        subtitle = groupTrainee?.button_description ?? '';
        dialogTitle = groupTrainee?.conclude_relationship_modal.title ?? '';
        dialogDescription =
          groupTrainee?.conclude_relationship_modal.description ?? '';
        concludeRelationshipButtonLabel =
          groupTrainee?.button_title ?? 'Conclude';
      }
    } else {
      heading = individual?.button_title ?? '';
      subtitle = individual?.button_description ?? '';
      dialogTitle = individual?.conclude_relationship_modal.title ?? '';
      dialogDescription =
        individual?.conclude_relationship_modal.description ?? '';
      concludeRelationshipButtonLabel = individual?.button_title ?? 'Conclude';
    }

    return {
      heading,
      subtitle,
      title: dialogTitle,
      description: dialogDescription,
      buttonLabel: concludeRelationshipButtonLabel,
      cancelButtonLabel,
    };
  };

  const getSuccess = () => {
    const groupGuide =
      programType?.program_type_text?.variations?.group?.relationships?.guide
        ?.conclude_relationship;
    const groupTrainee =
      programType?.program_type_text?.variations?.group?.relationships?.trainee
        ?.conclude_relationship;
    const individual =
      programType?.program_type_text?.variations?.individual?.relationships
        ?.conclude_relationship;

    if (isGroupProgram) {
      return isGuide
        ? groupGuide?.conclude_relationship_modal.success_alert_text ?? ''
        : groupTrainee?.conclude_relationship_modal.success_alert_text ?? '';
    }
    return individual?.conclude_relationship_modal.success_alert_text ?? '';
  };

  const getError = () => {
    const groupGuide =
      programType?.program_type_text?.variations?.group?.relationships?.guide
        ?.conclude_relationship;
    const groupTrainee =
      programType?.program_type_text?.variations?.group?.relationships?.trainee
        ?.conclude_relationship;
    const individual =
      programType?.program_type_text?.variations?.individual?.relationships
        ?.conclude_relationship;

    if (isGroupProgram) {
      return isGuide
        ? groupGuide?.conclude_relationship_modal.error_alert_text
        : groupTrainee?.conclude_relationship_modal.error_alert_text;
    }
    return individual?.conclude_relationship_modal.error_alert_text;
  };

  const concludeRelationship = async () => {
    let isSuccess = false;
    setIsLoading(true);

    const data =
      (isGroupProgram && isGuide) || !isGroupProgram
        ? { isConcluded: true }
        : { traineeProfiles: profileId ? [profileId] : [] };

    const updateRelationship = await reqRelationships({
      method: 'PATCH',
      url: `/relationships/${relationshipId}`,
      data,
    });

    isSuccess = updateRelationship.status === 'success';

    if (isSuccess) {
      trackMixpanelEvent('Relationship - Concluded', {
        'Concluded on': getISOStringWithoutMilliseconds(Date.now()),
        Program: programSlug,
        'Concluded by': isGuide ? 'guide' : 'trainee',
      });
    }

    dispatch(
      showAppAlert({
        severity: isSuccess ? 'success' : 'error',
        message: isSuccess ? getSuccess() : getError(),
        timeout: 5000,
      }),
    );

    await relationshipsRevalidate();

    navigate('/relationships');
    setIsLoading(false);
  };

  return { concludeRelationship, getModalConfig };
};

export default useConcludeRelationship;
